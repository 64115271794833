'use client'

import Image from "@/core/components/Image";
import Link from "next/link";

import React, { Fragment } from 'react'
import { Sms, Instagram, Location } from "iconsax-react";

import { logAction } from "@/core/helpers/analytics";

import iconTiktok from "@/images/icons/TikTokBlack.svg";
import appstoreLogo from "@/images/icons/IconAppStore.webp";
import googleplayLogo from "@/images/icons/IconGooglePlay.webp";
import LogoLamudi from '@/images/ic-logo-lamudi.svg';

export default function FooterMenu({ enableIdealPay = false }) {

    const leftColumn = [
        { href: '/partnership', text: 'Partnership', action: 'HP_FOOT_Partnership' },
        { href: '/about', text: 'About Us', action: 'HP_FOOT_AboutUs' },
        { href: '/referral', text: 'Referral', action: 'HP_FOOT_Referral' },
        { href: '/faq', text: 'FAQ', action: 'HP_FOOT_FAQ' },
        { href: '/privacy', text: 'Privacy & Policy', action: 'HP_FOOT_PrivacyPolicy' },
        { href: '/term', text: 'Terms of Use', action: 'HP_FOOT_TermsOfUse' },
        { href: process.env.NEXT_PUBLIC_AP_URL, text: 'Agent Portal', action: 'HP_FOOT_AgentPortal', external: true },
        { href: '/kpr-finder', text: 'KPR Finder', action: 'HP_FOOT_KPRFinder' },
        ...(enableIdealPay
            ? [{ href: '/ideal-pay', text: 'IDEAL Pay', action: 'HP_FOOT_IdealPay' }]
            : []),
    ];

    const rightColumn = [
        { href: '/product/primary', text: 'Primary Mortgage', action: 'HP_FOOT_PrimaryKPR' },
        { href: '/product/secondary', text: 'Secondary Mortgage', action: 'HP_FOOT_SecondaryKPR' },
        { href: '/product/takeover', text: 'Take Over Mortgage', action: 'HP_FOOT_TakeoverKPR' },
        { href: '/product/multiguna', text: 'Multiguna Loan', action: 'HP_FOOT_Multiguna' },
        { href: '/product/ideal-pass', text: 'IDEAL Pass', action: 'HP_FOOT_IdealScore' },
    ];

    const socialMedia = [
        { Icon: Location, text: 'OCBC Tower, Jl. Prof. Dr. Satrio Kav.25, 21st floor. Jakarta 12940', href: 'https://goo.gl/maps/7Pac2Zz1fMm8pbBe9' },
        { Icon: Sms, text: 'hello@ideal.id', href: 'mailto:hello@ideal.id' },
        { Icon: Instagram, text: '@ideal.idn', href: 'https://www.instagram.com/ideal.idn/?hl=en' },
        { Icon: null, text: '@ideal.idn', href: 'https://www.tiktok.com/@ideal.idn', isImage: true, image: iconTiktok },
    ];

    return (
        <Fragment>
            <div className="flex-1 relative">
                <div className="flex flex-row md:gap-10">
                    {[...Array(2)].map((_, colIndex) => (
                        <div key={colIndex} className="flex-1 relative">
                            {(colIndex === 0 ? leftColumn : rightColumn).map(({ href, text, action, external }, idx) => (
                                <div key={idx} className="mb-4">
                                    <Link
                                        href={href}
                                        className="lg:text-lg font-medium"
                                        onClick={() => logAction(action)}
                                        target={external ? '_blank' : undefined}
                                    >
                                        {text}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex flex-1 flex-col relative">
                <div className="mb-8 flex items-center gap-3.5">
                    {[process.env.NEXT_PUBLIC_IOS_APP_URL, process.env.NEXT_PUBLIC_ANDROID_APP_URL].map((link, index) => (
                        <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            className="block"
                            onClick={() => logAction(index === 0 ? 'HP_LS_AppStore' : 'HP_LS_PlayStore')}
                        >
                            <Image
                                src={index === 0 ? appstoreLogo : googleplayLogo}
                                alt={index === 0 ? 'App Store Logo' : 'Google Play Logo'}
                                loading="lazy"
                               
                                objectFit="contain"
                                height={44}
                                width={154}
                            />
                        </a>
                    ))}
                </div>

                <div className="grid gap-3 relative">
                    {socialMedia.map(({ Icon, text, href, isImage, image }, idx) => (
                        <div key={idx} className="flex items-center gap-3">
                            <div className="h-6 relative w-6">
                                {isImage ? (
                                    <Image src={image} alt="Icon" height={24} width={24} />
                                ) : (
                                    <Icon color="#25253F" size={24} variant="Bold" />
                                )}
                            </div>
                            <a href={href} target="_blank" rel="noreferrer">
                                {text}
                            </a>
                        </div>
                    ))}
                </div>
                <div className='flex flex-wrap gap-2 items-end justify-center mt-6 w-full lg:justify-start lg:mt-auto'>
                    <p className='caption2 mt-1'>© IDEAL INDONESIA is part of</p>
                    <Image src={LogoLamudi} alt='Logo Lamudi' height={28} width={70} />
                    <p className='caption2 mt-1'>group</p>
                </div>
            </div>
        </Fragment>
    )
}
